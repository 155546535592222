// Generated automatically by nearley, version unknown
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }

	const moo = require("moo")

	const lexer = moo.compile({
		ws:        { match: /\s+/, lineBreaks: true },
		comma:     /,/,
		integer:   /[0-9]+/,
		funcStart: /(?:SUM|sum|MIN|min|MAX|max)\(/,
		funcEnd:   /\)/,
		jsonField: /[a-z][a-zA-Z0-9]*/,
	});
var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "MAIN", "symbols": ["WS", "EXPRESSION"], "postprocess": ([_ws, start]) => start},
    {"name": "EXPRESSION", "symbols": [(lexer.has("integer") ? {type: "integer"} : integer)], "postprocess": ([integer]) => ({type: "LLMMarkingConstant", value: Number(integer.value)})},
    {"name": "EXPRESSION", "symbols": [(lexer.has("jsonField") ? {type: "jsonField"} : jsonField)], "postprocess": ([jsonField]) => ({type: "LLMMarkingVariable", name: jsonField.value})},
    {"name": "EXPRESSION", "symbols": [(lexer.has("funcStart") ? {type: "funcStart"} : funcStart), "WS", "ARGUMENTS", "WS", (lexer.has("funcEnd") ? {type: "funcEnd"} : funcEnd)], "postprocess": ([func, _ws1, args, _ws2, _fe]) => ({type: "LLMMarkingFunction", name: func.value.slice(0, -1).toUpperCase(), arguments: args})},
    {"name": "ARGUMENTS", "symbols": ["EXPRESSION"], "postprocess": ([exp]) => exp},
    {"name": "ARGUMENTS", "symbols": ["EXPRESSION", "WS", (lexer.has("comma") ? {type: "comma"} : comma), "WS", "ARGUMENTS"], "postprocess": ([exp, _ws1, _c, _ws2, args]) => [exp].concat(args)},
    {"name": "WS$ebnf$1", "symbols": []},
    {"name": "WS$ebnf$1", "symbols": ["WS$ebnf$1", (lexer.has("ws") ? {type: "ws"} : ws)], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "WS", "symbols": ["WS$ebnf$1"], "postprocess": (ws) => null}
]
  , ParserStart: "MAIN"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
